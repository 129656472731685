<template>
  <section class="newword-wrap">
    <!-- <side-bar/>
    <section class="push-content"> -->
    <!-- </section> -->
    <section class="content" v-loading="loading">
      <template>
        <div style="width: 20%;float: left;padding-left: 20px;margin-top: 40px">
          <el-tree :data="data" show-checkbox node-key="id"  @check-change="modifyIds" :default-checked-keys="checkedList" :default-expanded-keys="expandedList" ref="tree" style="padding-right: 10px;width: 150px;" :expand-on-click-node="true">
                <span class="custom-tree-node" slot-scope="{node, data}">
                  <span>{{ data.title }}</span>
                </span>
          </el-tree>
        </div>
        <div style="width:80%;padding-top: 40px;float: left">
          <span>情感范围</span><br>
          <el-checkbox-group v-model="form.emotionList" style="margin-top: 20px;margin-bottom: 20px">
            <el-checkbox label="0">正面</el-checkbox>
            <el-checkbox label="1">负面</el-checkbox>
            <el-checkbox label="2">敏感</el-checkbox>
          </el-checkbox-group>
          <span>来源</span><br>
          <el-checkbox-group v-model="form.sourceList" style="margin-top: 20px;margin-bottom: 20px">
            <el-checkbox label="1">网媒</el-checkbox>
            <el-checkbox label="2">贴吧</el-checkbox>
            <el-checkbox label="3">微信</el-checkbox>
            <el-checkbox label="4">博客</el-checkbox>
            <el-checkbox label="5">论坛</el-checkbox>
            <el-checkbox label="6">电子报</el-checkbox>
            <el-checkbox label="7">微博</el-checkbox>
            <el-checkbox label="8">全网</el-checkbox>
            <el-checkbox label="9">问答</el-checkbox>
            <el-checkbox label="10">客户端</el-checkbox>
          </el-checkbox-group>
          <el-button type="primary" style="margin-top: 20px" @click="toggle">保存</el-button>
          <el-button type="" style="margin-top: 20px" @click="cancelSet">取消</el-button>
        </div>
      </template>
    </section>
  </section>
</template>
<script>
  import { selectAllType3, getAutoReportSet, updateAutoReportSet } from '@/api/dataOutput'
  export default {
  data () {
    return ({
      data: [],
      expandedList: [],
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      loading: true,
      form: {
        customerName: '',
        emotionList: [],
        sourceList: []
      },
      checkedList: []
    })
  },
  components: {
  },
  mounted () {
    this.allMS()
    this.getSet()
    this.loading = false
  },
  methods: {
    modifyIds (data, checked, node) {
      if(checked) {
        this.$refs.tree.setCheckedNodes([data])
      }
    },
    async getSet () {
      const res = await getAutoReportSet()
      console.log(res)
      this.form.emotionList = res.data.emotionRange.split(',')
      this.form.sourceList = res.data.sourceRange.split(',')
      this.checkedList = res.data.subjectList.split(',')
    },
    cancelSet () {
      window.close()
    },
    async allMS () {
      const res = await selectAllType3()
      for (var i = 0; i < res.data.length; i++) {
        res.data[i].disabled = false
        this.expandedList.push(res.data[i].id)
        // this.checkedList.push(res.data[i].id)
      }
      this.data = res.data
    },
    async toggle () {
      // console.log(this.$refs.treeCon.getCheckedNodes())
      var trees = this.$refs.tree.getCheckedNodes()
      var ids = []
      for (var i = 0; i < trees.length; i++) {
        ids.push(trees[i].id)
      }
      console.log(String(ids))
      console.log(String(this.form.emotionList))
      console.log(String(this.form.sourceList))
      const res = await updateAutoReportSet({ emotionRange: String(this.form.emotionList), sourceRange: String(this.form.sourceList), subjectList: String(ids) })
      if (res.code === 0) {
        this.$message.success('修改成功')
      } else {
        this.$message.error('修改失败')
      }
    }
  }
}
</script>
<style lang="scss" scoped>
  @import '@/styles/index';
  .newword-wrap {
    width: 100%;
    height: 100vh;
    /* padding: 30px 30px;
    display: flex;
    .push-content {
      width: 100%;
      min-height: $contentHeight;
      margin-left: 30px;
    } */
    .content {
      width: 100%;
      height: 100vh;
      min-height: calc(100% - 90px);
      // margin-top: 20px;
      border-radius: 10px;
      box-shadow: 0px 0px 6px 0px rgba(53, 64, 82, 0.06);
      // padding: 20px 30px;
      position: relative;
      background: #fff;
    }
  }
</style>
